
import { defineComponent, ref } from 'vue';
import MakeTable from '../components/MakeTable.vue';

export default defineComponent({
    components: {
        MakeTable,
    },
    props: {
        trackingOverviewData: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    setup() {
        const makeTableEl = ref(null);
        const exportExcel = () => {
            (makeTableEl.value as any).exportExcel();
        };

        return { makeTableEl, exportExcel };
    },
});
