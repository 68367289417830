
import { defineComponent, ref, provide, watch, nextTick, onMounted } from "vue";
import moment, { Moment } from "moment";
import TrackingOverview from "./TrackingOverview/index.vue";
import ProgramTrackingDetail from "./ProgramTrackingDetail/index.vue";
import { getCheckingOverviewList } from "@/API/salesReporting";
import { useStore } from "vuex";

const INIT_YEAR = "2022";

export default defineComponent({
  components: {
    TrackingOverview,
    ProgramTrackingDetail,
  },
  setup() {
    const spaceSize = 6;
    const activeKey = ref("1");

    // make
    const make = ref<string | undefined>("CBU");
    const makeList = ref(["CBU", "PBP", "VAN"]);

    // start year
    const startYear = ref<string | undefined>(INIT_YEAR); //TODO 最早年份
    // close or not
    const closeOrNot = ref<string | undefined>("2");
    const closeOrNotList = ref([
      {
        id: "0",
        name: "Y",
      },
      {
        id: "1",
        name: "N",
      },
      {
        id: "2",
        name: "All",
      },
    ]);

    const reset = () => {
      startYear.value = INIT_YEAR;
      closeOrNot.value = "2";
      make.value = "CBU";
    };

    const yearList = ref<string[]>([]);
    // provide('make', make);
    // provide('startYear', startYear);
    // provide('closeOrNot', closeOrNot);
    const store = useStore();
    watch(
      [make, startYear, closeOrNot, yearList],
      ([newMake, newStartYear, newcloseOrNot, newYearList]) => {
        store.commit("salesReport/setFCSearchParams", {
          make: newMake,
          startYear: newStartYear,
          closeOrNot: newcloseOrNot,
          yearList: newYearList,
        });
      },
      {
        immediate: true,
      }
    );

    const trackingOverviewData = ref<any>([]);
    const search = () => {
      const params = {
        // make: 'CBU',
        // startYear: '2014',
        // closeOrNot: '2',
        make: make.value,
        startYear: startYear.value,
        closeOrNot: closeOrNot.value,
      };
      getCheckingOverviewList(params).then((res: any) => {
        trackingOverviewData.value = res;
        yearList.value = res.yearList;
      });
    };

    // const downloadClick = () => {
    //     const downloadParams = {
    //         make: make.value,
    //         startYear: startYear.value,
    //         closeOrNot: closeOrNot.value,
    //     };
    //     const params = {
    //         url: `/rpapi/paymentTrackingFC/trackingOverview/export`,
    //         method: 'post',
    //         params: downloadParams,
    //     };
    //     downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
    // };

    const open = ref(false);
    const initYear = INIT_YEAR;
    const handleStartYearPanelChange = (val: Moment) => {
      const selectedYear = val.format("YYYY");
      // if (selectedYear >= initYear) {
      //   startYear.value = selectedYear;
      //   open.value = false;
      // }
      startYear.value = selectedYear;
      open.value = false;
    };
    const dateList = ref<any[]>([]);
    watch(
      dateList,
      (newVal) => {
        newVal.forEach((element: any) => {
          if (element.innerText < initYear) {
            element.classList.add("disabled-years");
          }
        });
      },
      { deep: true }
    );
    const disabledYears = () => {
      nextTick(() => {
        const dateDom = document.querySelector(
          ".ant-calendar-year-panel-decade-select"
        );
        dateList.value = Array.from(
          document.getElementsByClassName("ant-calendar-year-panel-year")
        );
        (dateDom as HTMLElement).addEventListener(
          "DOMCharacterDataModified",
          () => {
            nextTick(() => {
              dateList.value = Array.from(
                document.getElementsByClassName("ant-calendar-year-panel-year")
              );
            });
          }
        );
      });
    };
    const handleStartYearOpenChange = (status: boolean) => {
      open.value = status;
      // disabledYears();
    };

    onMounted(() => {
      search();
    });

    return {
      activeKey,
      spaceSize,
      make,
      makeList,
      startYear,
      closeOrNot,
      closeOrNotList,
      search,
      trackingOverviewData,
      open,
      handleStartYearPanelChange,
      handleStartYearOpenChange,
      reset,
    };
  },
});
