
import {
    defineComponent,
    ref,
    createVNode,
    computed,
    toRefs,
    watch,
    nextTick,
    onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ColumnsType } from '../types';
import downloadFile from '@/utils/claim/downloadFile';

const INDENT_ROW = [
    'OASIS Approved',
    'Pending Adj.',
    't/o to-be released',
    't/o closed',
    't/o open',
];

export default defineComponent({
    props: {
        trackingOverviewData: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const make = computed(
            () => store.state.salesReport.fcSearchParams.make
        );
        const startYear = computed(
            () => store.state.salesReport.fcSearchParams.startYear
        );
        const closeOrNot = computed(
            () => store.state.salesReport.fcSearchParams.closeOrNot
        );
        //* 表格第一列 固定行的行头渲染逻辑
        const renderRowName = ({ record }: any) => {
            const obj = {
                children: record.rowName,
                props: { class: 'weight-font' } as any,
            };
            if (record.rowName === 'Possible Release (Accrual vs. Payout BE)') {
                return createVNode('div', {}, [
                    createVNode(
                        'div',
                        { class: 'weight-font' },
                        'Possible Release'
                    ),
                    createVNode(
                        'div',
                        { class: 'weight-font' },
                        '(Accrual vs. Payout BE)'
                    ),
                ]);
            }
            if (record.rowName === 't/o already released (-)') {
                return createVNode(
                    'p',
                    {
                        onClick: ($event: any) => {
                            router.push({
                                // path:
                                //     '/salesReporting/paymentTrackingReportReleaseInput',
                                name: 'PaymentTrackingReportReleaseInput',
                            });
                        },
                        class: 'indent',
                        style: { color: '#00adef', cursor: 'pointer' },
                    },
                    `${record.rowName}`
                );
            }
            if (INDENT_ROW.includes(record.rowName)) {
                return createVNode(
                    'p',
                    { class: 'indent' },
                    `${record.rowName}`
                );
            }
            return obj;
        };

        // 表格列名
        const columnsAll = ref<ColumnsType[]>([]);

        // 假数据
        const dataSource = ref<any[]>([]);
        const initFirstColumn: any[] = [
            { rowName: 'Program Accrual' },
            { rowName: 'Payout Best Estimation' },
            { rowName: 'OASIS Approved' },
            { rowName: 'Pending Adj.' },
            { rowName: 'Possible Release (Accrual vs. Payout BE)' },
            { rowName: 't/o already released (-)' },
            { rowName: 't/o to-be released' },
            { rowName: 'No. of Program' },
            { rowName: 't/o closed' },
            { rowName: 't/o open' },
        ];
        dataSource.value = initFirstColumn;
        const { trackingOverviewData } = toRefs(props);
        const { yearList, dataList, title } = toRefs(
            trackingOverviewData.value
        );

        const subColumns = {
            'Direct Subsidy': 'directSubsidy',
            'Payout to Dealer': 'payoutToDealer',
            'Payout to dealer': 'payoutToDealer',
            PMT: 'pmt',
        };

        //* 动态生成表格数据
        const generateTableData = (title: string[], dataList: any) => {
            title.shift();
            const tableData = initFirstColumn.reduce(
                (all: any, ele: any, index: number) => {
                    const data = dataList[index];
                    let currentYear = '';
                    const otherRowData = title.reduce(
                        (row: any, til: any, ind: number) => {
                            let columnName = '';
                            if (til[0] === 'Y') {
                                currentYear = til.substring(1);
                                columnName = til.substring(1);
                            } else {
                                const prefix = subColumns[til];
                                // .replaceAll(' ', '')
                                // .toLowerCase();
                                columnName = `${prefix}_${currentYear}`;
                            }
                            return { ...row, [columnName]: data[ind] };
                        },
                        {}
                    );
                    return [...all, { ...ele, ...otherRowData }];
                },
                []
            );
            dataSource.value = tableData;
            store.commit('salesReport/setPossibleReleaseRowData', tableData[4]);
            // console.log('tableData', tableData);
        };

        const distinctColor = (column: any, type: string) => {
            return { class: [type] };
        };

        //* 动态生成表列名
        const generateColumnsAll = (title: string[]) => {
            title.shift();
            const allColumns: ColumnsType[] = [];
            let currentColumn: ColumnsType;
            let currentYear = '';
            for (const til of title) {
                let columnName = '';
                if (til[0] === 'Y') {
                    currentYear = til.substring(1);
                    columnName = til.substring(1);
                    currentColumn = {
                        cTitle: columnName,
                        display: true,
                        parent: null,
                        dataIndex: columnName,
                        key: columnName,
                        canExpand: true,
                        expandStatus: false,
                        slots: { title: columnName },
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-year'),
                    };
                } else {
                    const prefix = subColumns[til];
                    columnName = `${prefix}_${currentYear}`;
                    currentColumn = {
                        cTitle: til,
                        display: false,
                        parent: currentYear,
                        dataIndex: columnName,
                        key: columnName,
                        canExpand: false,
                        slots: { title: columnName },
                        customHeaderCell: (column: any) =>
                            distinctColor(column, 'distinct-subCol'),
                    };
                }
                allColumns.push(currentColumn);
            }
            columnsAll.value = allColumns;
            // console.log('allColumns', allColumns);
        };

        watch(trackingOverviewData, (newVal) => {
            // console.log('trackingOverviewData', newVal);
            generateTableData(
                JSON.parse(JSON.stringify(newVal.title)),
                newVal.dataList
            );
            generateColumnsAll(JSON.parse(JSON.stringify(newVal.title)));
        });
        const showDataSource = () => {
            console.log('dataSource', dataSource);
            console.log(
                'yearList,dataList, title::',
                yearList.value,
                dataList.value,
                title.value
            );
        };

        const displayColumnsClick = (parentTitle: string) => {
            columnsAll.value.forEach((col: any) => {
                if (col.parent === parentTitle) {
                    col.display = !col.display;
                }
                if (col.cTitle === parentTitle) {
                    col.expandStatus = !col.expandStatus;
                }
            });
        };

        const columns = computed(() => {
            const cols = columnsAll.value.filter((col: any) => {
                return col.display;
            });
            cols.unshift({
                dataIndex: 'rowName',
                key: 'rowName',
                fixed: 'left',
                width: 200,
                slots: { title: 'rowName' },
                customRender: renderRowName,
                customHeaderCell: (column: any) =>
                    distinctColor(column, 'distinct-year'),
            });
            return cols;
        });

        const colWidth = computed(() => {
            const defaultWidth = 150;
            const len = columnsAll.value.filter((col: any) => {
                return col.display;
            }).length;
            return defaultWidth * len + 200;
        });

        const exportExcel = () => {
            const downloadParams = {
                make: make.value,
                startYear: startYear.value,
                closeOrNot: closeOrNot.value,
            };
            const params = {
                url: `/rpapi/paymentTrackingFC/trackingOverview/export`,
                method: 'post',
                params: downloadParams,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        const colHeight = ref(500);
        const calculateHeight = () => {
            const table = document.getElementById('makeTable');
            const clinetHeight = document.body.clientHeight;
            if (table) {
                const top = table.getBoundingClientRect().top;
                colHeight.value = clinetHeight - top - 115;
            }
        };

        const totalRowClass = (record: any) => {
            const weights = [
                'Program Accrual',
                'Payout Best Estimation',
                'Possible Release (Accrual vs. Payout BE)',
                't/o already released (-)',
                't/o to-be released',
                'No. of Program',
            ];
            if (weights.includes(record.rowName)) {
                return 'weight-font';
            }
        };

        nextTick(() => {
            calculateHeight();
        });

        onMounted(() => {
            window.addEventListener('resize', calculateHeight);
        });

        return {
            renderRowName,
            dataSource,
            columns,
            colWidth,
            displayColumnsClick,
            showDataSource,
            exportExcel,
            make,
            colHeight,
            totalRowClass,
        };
    },
});
