
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import moment, { Moment } from "moment";
import TrackingDetailTable from "../components/TrackingDetailTable.vue";
import { getProgramTrackingDetailList } from "@/API/salesReporting";
import downloadFile from "@/utils/claim/downloadFile";

const INIT_YEAR = "2022";

export default defineComponent({
  components: {
    TrackingDetailTable,
  },
  setup() {
    const store = useStore();
    const fcSearchParams = computed(() => {
      return store.state.salesReport.fcSearchParams;
    });
    const spaceSize = 6;
    const checkedList = ref(["1", "2", "3"]);
    const checkOptions = [
      { label: "Direct Subsidy", value: "1" },
      { label: "Payout to dealer", value: "2" },
      { label: "PMT", value: "3" },
    ];
    const selectYear = ref<string | undefined>(INIT_YEAR);
    const trackingDetailData = ref<any>({ dataList: [], total: [] });
    const saveParams = ref<any>();
    const search = () => {
      const params = {
        make: fcSearchParams.value.make,
        closeOrNot: fcSearchParams.value.closeOrNot,
        startYear: fcSearchParams.value.startYear,
        typeList: checkedList.value,
        selectYear: selectYear.value,
      };
      saveParams.value = {
        make: fcSearchParams.value.make,
        closeOrNot: fcSearchParams.value.closeOrNot,
        startYear: fcSearchParams.value.startYear,
        yearList: fcSearchParams.value.yearList,
        typeList: checkedList.value,
        selectYear: selectYear.value,
      };
      getProgramTrackingDetailList(params).then((res: any) => {
        trackingDetailData.value.dataList = res.dataList;
        trackingDetailData.value.total = res.total;
      });
    };
    const downloadClick = () => {
      const downloadParams = {
        make: fcSearchParams.value.make,
        closeOrNot: fcSearchParams.value.closeOrNot,
        startYear: fcSearchParams.value.startYear,
        yearList: fcSearchParams.value.yearList,
        typeList: checkedList.value,
        selectYear: selectYear.value,
      };
      const params = {
        url: `/rpapi/paymentTrackingFC/programTrackingDetail/export`,
        method: "post",
        params: downloadParams,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    const trackingDetailTable = ref(null);
    const save = () => {
      (trackingDetailTable.value as any).save();
    };

    const open = ref(false);
    const initYear = INIT_YEAR;
    const handleSelectYearPanelChange = (val: Moment) => {
      const selected = val.format("YYYY");
      // if (selected >= initYear) {
      //   selectYear.value = selected;
      //   open.value = false;
      // }
      selectYear.value = selected;
      open.value = false;
    };
    const dateList = ref<any[]>([]);
    watch(
      dateList,
      (newVal) => {
        newVal.forEach((element: any) => {
          if (element.innerText < initYear) {
            element.classList.add("disabled-years");
          }
        });
      },
      { deep: true }
    );
    const disabledYears = () => {
      nextTick(() => {
        const dateDom = document.querySelector(
          ".ant-calendar-year-panel-decade-select"
        );
        dateList.value = Array.from(
          document.getElementsByClassName("ant-calendar-year-panel-year")
        );
        (dateDom as HTMLElement).addEventListener(
          "DOMCharacterDataModified",
          () => {
            nextTick(() => {
              dateList.value = Array.from(
                document.getElementsByClassName("ant-calendar-year-panel-year")
              );
            });
          }
        );
      });
    };
    const handleSelectYearOpenChange = (status: boolean) => {
      open.value = status;
      // disabledYears();
    };

    onMounted(() => {
      search();
    });

    return {
      spaceSize,
      checkedList,
      checkOptions,
      search,
      trackingDetailData,
      downloadClick,
      trackingDetailTable,
      save,
      saveParams,
      selectYear,
      open,
      handleSelectYearPanelChange,
      handleSelectYearOpenChange,
      disabledYears,
    };
  },
});
