import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af45a51c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "expand-icon"
}
const _hoisted_4 = {
  key: 1,
  class: "expand-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    id: "makeTable",
    rowKey: (item, index) => index,
    dataSource: _ctx.dataSource,
    columns: _ctx.columns,
    pagination: false,
    scroll: {
            x: _ctx.colWidth,
            y: _ctx.colHeight,
        },
    rowClassName: _ctx.totalRowClass,
    bordered: ""
  }, _createSlots({
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.make), 1)
    ]),
    _: 2
  }, [
    _renderList(_ctx.columns, (col) => {
      return {
        name: col.slots.title,
        fn: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("span", null, _toDisplayString(col.cTitle), 1),
            (col.canExpand)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.displayColumnsClick(col.cTitle))
                }, [
                  (col.expandStatus)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, " -"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, " +"))
                ], 8, _hoisted_2))
              : _createCommentVNode("", true)
          ])
        ])
      }
    })
  ]), 1032, ["rowKey", "dataSource", "columns", "scroll", "rowClassName"]))
}